import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";

interface ProgramDetailsType {
  title: string;
  selected?: string;
}

const ProgramDetails: ProgramDetailsType[] = [
  {
    title: "Intensive Glycemic Control Plan",
  },
  {
    title: "Advanced Diabetes Care Plan",
  },
  {
    title: "Pre-Diabetes Reversal Program",
  },
  {
    title: "Digital Diabetes Management Plan",
  },
];

const ProgramMap: React.FC<ProgramDetailsType> = ({ title, selected }) => {
  return (
    <div>
      <div
        className={` w-full p-4 rounded-md flex gap-4 ${
          title === selected
            ? "bg-primary-900 text-white"
            : "bg-white border border-[#6993FF]"
        }`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          className={`${title === selected ? "" : "hidden"}`}
          fill="none"
          viewBox="0 0 24 24">
          <g clipPath="url(#clip0_5626_7087)">
            <path
              fill="#fff"
              d="M11.602 13.76l1.412 1.412 8.466-8.466 1.414 1.414-9.88 9.88-6.364-6.364 1.414-1.414 2.125 2.125 1.413 1.412v.001zm.002-2.828l4.952-4.953 1.41 1.41-4.952 4.953-1.41-1.41zm-2.827 5.655L7.364 18 1 11.636l1.414-1.414 1.413 1.413-.001.001 4.951 4.951z"></path>
          </g>
          <defs>
            <clipPath id="clip0_5626_7087">
              <path fill="#fff" d="M0 0H24V24H0z"></path>
            </clipPath>
          </defs>
        </svg>
        <div>{title}</div>
      </div>
    </div>
  );
};

const ProgramSuccess: React.FC = () => {
  const navigate = useNavigate();

  const selected =
    sessionStorage.getItem("program") || "Digital Diabetes Management Plan";

  const handleGetStarted = () => {
    navigate("/booking-flow");
  };

  const handleGoHome = () => {
    navigate("/assessment");
  };

  return (
    <div
      style={{
        backgroundImage: `url(/32.png)`,
      }}
      className="flex bg-no-repeat pb-20 min-h-screen w-full bg-cover bg-center justify-start items-center flex-col  mx-auto">
      <img src={logo} alt="logo" className="mt-10 w-60 py-5" />
      <h2 className="mb-4 text-3xl text-center text-[#314DD3]">
        We are almost there! <br />
        <div className="max-w-2xl mt-4">
          Based on your responses, you are eligible for {selected}.
        </div>
      </h2>
      <div className="text-center max-w-md">
        <br /> Lets get started!
      </div>
      <div className="mt-5 w-96 flex flex-col gap-4">
        {ProgramDetails.map((program) => (
          <ProgramMap
            key={program.title}
            title={program.title}
            selected={selected}
          />
        ))}
      </div>

      <div className="flex gap-4">
        <button
          onClick={handleGoHome}
          className={`w-max px-12 mr-4 py-3 mt-10  rounded font-bold border text-black`}
          type="button">
          Go Home
        </button>
        <button
          onClick={handleGetStarted}
          className={`w-max px-12 py-3 mt-10 text-white rounded font-bold bg-[#222424]`}
          type="button">
          Book Appointment
        </button>
      </div>
    </div>
  );
};

export default ProgramSuccess;
