import { Navigate } from "react-router-dom";
import { useAuthenticated } from "../shared/hooks";
import Auth0Login from "./auth0.component";

const Auth: React.FC = () => {
  const isAuthenticated = useAuthenticated();
  // if (isAuthenticated && subDomainData.isAuth) {
  //   return <Navigate to="/insurance-flow" />;
  // }
  if (isAuthenticated) {
    return <Navigate to="/assessment" />;
  }

  return <Auth0Login />;
};

export default Auth;
