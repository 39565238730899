import { useNavigate } from "react-router-dom";
import heroImg from "../../assets/images/HeroImage2.jpg";
import { useFormStore } from "../../store/form";
import Step from "./components/step.component";
interface ISteps {
  step: number;
  setStep: (step: number) => void;
}

const Steps: React.FC<ISteps> = ({ step, setStep }) => {
  const navigate = useNavigate();

  const { referralCode } = useFormStore();

  // if (referralCode === null) {
  //   navigate("/");
  // }

  const steps = referralCode?.includes("PSA-001")
    ? [
        {
          title: "1. Provide Personal Information",
        },
      ]
    : [
        {
          title: "1. Provide Personal Information",
        },
        {
          title: "2. Schedule Session",
        },
      ];

  return (
    <div
      style={{
        backgroundImage: `url('${heroImg}')`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#000000",
        height: "94vh",
      }}
      className="bg-[#F6F6FB] flex-1 pr-32 mt-0 pl-32 pt-8 flex flex-col gap-6 max-lg:flex-col-reverse max-lg:w-full max-lg:px-5 max-lg:bg-[#F6F6FB] max-lg:pt-5">
      {/* <div className="w-full h-full absolute bg-white top-0 left-0 opacity-40" /> */}
      <div className="max-lg:flex z-30">
        {steps.map((item, index) => (
          <Step
            setStep={setStep}
            key={index}
            completed={step > index}
            step={index + 1}
            currentStep={step}
            active={step === index}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default Steps;
